import React from 'react'
import { Placeholder } from 'semantic-ui-react'

const PlaceholderList = (props) => (
<tr>
      <td colspan={props.colspan }> 
          
  <Placeholder fluid>
  <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>
    <Placeholder.Header image>
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder.Header>
  </Placeholder>
      </td>
</tr>
)

export default PlaceholderList
