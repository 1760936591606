
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link,Redirect } from 'react-router-dom';
import Api from './../../config/Api';
import { Routes } from "../../routes";
import BgImage from "../../assets/img/illustrations/signin.svg";
import {Error} from '../../components/Alerts';



class Signin extends React.Component{

  constructor(props){
    super(props);
    this.state ={
        email:'',
        password:'',
        error:'',
       success:false,
       islogin:localStorage.getItem('token')
    }
  }

  handleemail = e=> {this.setState({email:e.target.value})}
  handlepassword = e=> {this.setState({password:e.target.value})}
  handleSubmit = e => {
    
    if(this.state.email == ''){
      this.setState({error:"Please enter email"});
    }else{
      if (this.state.password == '') {
        this.setState({error:"Please enter your password"});
      }else{

      this.setState({error:''});
      var data = {
        email:this.state.email,
        password:this.state.password,
      }
      Api.post('login',data).then((response)=> {
         this.setState({success:true});
         this.setState({islogin:'true'});
          localStorage.setItem('token',true);
      }).catch((e)=>{
        let message = e.response.data.message;
        this.setState({error:message})
      })
      }
    }

  }

  render(){


    if(this.state.islogin == 'true'){
        window.location.reload();
      return  <Link to={Routes.DashboardOverview.path} />
    }

     return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>

          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to our platform</h3>
                </div>
                <Form  onSubmit={this.handleSubmit} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control onChange={this.handleemail} autoFocus required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="password" className="mb-4">
                      <Form.Label>Your Password</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control onChange={this.handlepassword} required type="password" placeholder="Password" />
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>
                      </Form.Check>
                      
                    </div>
                  </Form.Group>
                    <Error message={this.state.error} />
                  <Button  onClick={this.handleSubmit}
                   variant="primary" type="button" className="w-100">
                    Sign in
                  </Button>
                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
  }
}



export default Signin;