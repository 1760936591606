import React from "react";
import { Col, Row, Card, Form, Button, InputGroup } from '@themesberg/react-bootstrap';
import {Error,Success} from '../../components/Alerts';
import Api from '../../config/Api';
import { Redirect } from 'react-router-dom'
// import PlaceholderList from './../components/Placeholder';

class CreateCountry extends React.Component{

  constructor(props){
    document.title = `Add new Country | ${process.env.REACT_APP_NAME}` 
    super(props);
    this.state={
      countryname:'',
      error:'',
      success:false
    }

  }

  

  handlename = e=> {this.setState({countryname:e.target.value})}
  handleSubmit = e => {
    
    if(this.state.countryname == ''){
      this.setState({error:"Please enter country name"});
    }else{
      this.setState({error:''});
      var data = {
        name:this.state.countryname
      }
      Api.post('country',data).then((response)=> {
        this.setState({success:true})
      }).catch((e)=>{
        let message = e.response.data.message;
        this.setState({error:message})
      })

    }

  }

  render(){
    if(this.state.success){
      return <Redirect  to={{
              pathname: '/countries',
              state: "Country Created Successfully"
            }} />
    }
     return (
       
      <Row className="mt-4"  >
        <Col xs={12} xl={8}>
            <Card border="light" className="bg-white shadow-sm mb-4">
              <Card.Body>
                <Form  >
                  <Row>
                    <Col md={6} className="mb-3">      
                        <Form.Label>Country Name</Form.Label>
                        <Form.Control onChange={this.handlename}  required type="text" placeholder="Enter your country name" />
                        <Error message={this.state.error} />
                    </Col>
                    <Col md={12} className="mt-4">
                        <Button variant="primary"  onClick={this.handleSubmit} type="button">Save </Button>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
        </Col>
      </Row>);
  }

}



export default CreateCountry;
