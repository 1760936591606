import React from 'react'
import { Icon, Label, Menu, Table} from 'semantic-ui-react'
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

const TableRow = (props) => {
    const { chat_id,user_details,last_message,sender_id,variant} = props;

    return (
        <Table.Row>
        <Table.Cell>{user_details.name}</Table.Cell>
        <Table.Cell>{user_details.email}</Table.Cell>
        <Table.Cell>{last_message.last_message}</Table.Cell>
        <Table.Cell>{last_message.created_at.substr(0,10)}</Table.Cell>
        <Table.Cell>
            <Card.Link as={Link} to={`/chatview/${(variant == 2) ? 'teacher' : 'student'}/${chat_id}/${sender_id}`} className="fw-normal">
                 <Button > View </Button> 
             </Card.Link></Table.Cell>
      </Table.Row>
    );
  };

const ChatList = (props) => (
    <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>{ (props.variant == 1) ? 'Teacher' : 'Student' } Name</Table.HeaderCell>
        <Table.HeaderCell>Email </Table.HeaderCell>
        <Table.HeaderCell>Last Message </Table.HeaderCell>
        <Table.HeaderCell> Last Message (Time) </Table.HeaderCell>
        <Table.HeaderCell>View </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>


    { (props.chat.length == 0 ) ? ' No Record found' :  props.chat.map((t) => {
      t.variant = props.variant ;
      return <TableRow {...t}/>;
    })}
      
    </Table.Body>

    
  </Table>
)

export default ChatList
