import React from 'react'
import { Icon, Label, Menu, Table } from 'semantic-ui-react'


import { faStar } from '@fortawesome/free-solid-svg-icons';



const MyRow =(props) => (
  <tr>
      <th scope="row">{props.lable}</th>
      <td>{props.value}</td>
  </tr>
);



const ShowDetails = (props) => (
  <table class="table">
  <tbody>
    
  <MyRow 
    lable="Name"
    value={props.name} />
    
    <MyRow 
    lable="Email"
    value={props.email} />

  <MyRow 
    lable="Phone"
    value={props.phone} />
    

    {(props.variant == 'teacher') ? <MyRow 
    lable="Subjects"
    value={props.subjects} /> : ''}
    
    
    <MyRow 
    lable="Country"
    value={props.country} />
    
    {(props.variant == 'teacher') ? <MyRow 
    lable="description"
    value={props.description} /> : ''}
    
    {(props.variant == 'teacher') ?<tr>
      <th scope="row">Total Rating</th>
      <td> <Icon  name='star' /> { props.avg_rating }</td>
  </tr>: ''}
 
 <MyRow 
    lable="nationality"
    value={props.nationality} />
 

 <br></br>
 


  </tbody>
</table>
)

export default ShowDetails


