import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch,faEye,faEllipsisH,faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import moment from "moment-timezone";
import API from '../../config/Api';
import PlaceholderList from './../components/Placeholder';  

class Teachers extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true
    }
  }

  componentDidMount(){
    document.title = `Teachers list | ${process.env.REACT_APP_NAME}` 
     API.get(`/teachers`).then((response) => {
      console.log(response.data.data);
      this.setState({
      list:response.data.data,
      loading:false
      })
    });
    
  }

  onClickActive() {
    let id = arguments[0];
    
    
    let Confirm  = window.confirm('Do you want to Change Status');
    
    if(Confirm){
      this.setState({
        loading:true
        })
      API.post('user/change-status',{user_id:id}).then((response)=>{
     
      
        API.get(`/teachers`).then((response) => {
          console.log(response.data.data);
          this.setState({
          list:response.data.data,
          loading:false
          })
        });
      }).catch((e)=> {
        alert(e.response.data.message);
      })
    }

  }


  render(){

    let totalsubjects = 0;
    this.state.list.map((array) => {
      if(array.verified == true){
        totalsubjects++;
      }
    })
    const TableRow = (props) => {
    const { id,name,email,subjects,phone,nationality,createdAt,country,active_status,verified } = props;
    return (verified == true) 
      ? (
      <tr>
        <td><span className="fw-normal">{id}</span></td>
        <td><span className="fw-normal">{name}</span></td>
        <td><span className="fw-normal">{email}</span></td>
        <td><span className="fw-normal">{phone}</span></td>
        <td><span className="fw-normal">{nationality}</span></td>
        <td><span className="fw-normal">
          {
            (active_status == 0) ? <Button onClick={this.onClickActive.bind(this, id)} variant='success'> Activate </Button>  : <Button onClick={this.onClickActive.bind(this, id)}  variant='danger' > Deactivate </Button>  
          }
       
          </span></td>
        {/* <td><span className="fw-normal">{country}</span></td>
        <td><span className="fw-normal">{subjects}</span></td> */}
        <td><span className="fw-normal">{moment(createdAt).format('Do MMM YY')}</span></td>
        <td><span className="fw-normal">
        <Card.Link as={Link} to={`/teacher/${id}`} className="fw-normal">
                 <Button > View </Button> 
             </Card.Link>
           
          </span></td>
      </tr>
    ) : '';
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Teachers</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Teachers List</h4>
          <p className="mb-0">All Teachers</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

      
     <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">#</th>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Email</th>
              <th className="border-bottom">Phone </th>
              <th className="border-bottom">Nationality</th>
              <th className="border-bottom">Active/Deactive</th>
              {/* <th className="border-bottom">Country</th>
              <th className="border-bottom">Subjects</th> */}
              <th className="border-bottom">Created At</th>
              <th className="border-bottom">View</th>
              
            </tr>
          </thead>
          <tbody>
          { (this.state.loading == true) 
          ?  <PlaceholderList colspan='8' /> :
            (totalsubjects == 0 ) ? <tr><td colSpan='8'>  No Records</td></tr> : this.state.list.map(t => <TableRow  {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
            {/* <Pagination className="mb-2 mb-lg-0">
              <Pagination.Prev>
                Previous
              </Pagination.Prev>
              <Pagination.Item active>1</Pagination.Item>
              <Pagination.Item>2</Pagination.Item>
              <Pagination.Item>3</Pagination.Item>
              <Pagination.Item>4</Pagination.Item>
              <Pagination.Item>5</Pagination.Item>
              <Pagination.Next>
                Next
              </Pagination.Next>
            </Pagination> */}
          </Nav>
          <small className="fw-bold">
            Showing <b>{totalsubjects}</b> out of <b>{totalsubjects}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
     
    </>);
  }

}


export default Teachers;



