
import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookOpen,  faMap, faUser, faUserGraduate } from '@fortawesome/free-solid-svg-icons';
import { Col, Row,  } from '@themesberg/react-bootstrap';
import { CounterWidget } from "../../components/Widgets";

import API from './../../config/Api'



class Dashboard extends React.Component
{

  constructor(props){
    super(props);
    this.state={
      UserCount:0,
      TeacherCount:0,
      SubjectCount:0,
      CountryCount:0,
    }
  }

  componentDidMount(){

    document.title = "Dashboard Overview"
    API.get('/dashboard').then((response)=> {
      console.log(response.data)
     this.setState({
       UserCount:response.data.data.Students,
       TeacherCount:response.data.data.Teachers,
       SubjectCount:response.data.data.Subjects,
       CountryCount:response.data.data.Countries,
     })
    })
  }


  render(){
    return (
      <Row className="justify-content-md-center">
        
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Students"
            title={this.state.UserCount}
            percentage={18.2}
            icon={faUser}
            iconColor="shape-secondary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Teachers"
            title={this.state.TeacherCount}
            percentage={28.4}
            icon={faUserGraduate}
            iconColor="shape-tertiary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Subjects"
            title={this.state.SubjectCount}
            // percentage={28.4}
            icon={faBookOpen}
            iconColor="shape-tertiary"
          />
        </Col>
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <CounterWidget
            category="Countries"
            title={this.state.CountryCount}
            // percentage={28.4}
            icon={faMap}
            iconColor="shape-tertiary"
          />
        </Col>

      </Row>
    )
  }
}



export default Dashboard