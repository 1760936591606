import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCog, faHome, faSearch,faEye,faEllipsisH,faEdit,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Form, Button, ButtonGroup, Breadcrumb, InputGroup, Dropdown, Card,Table,Nav,Pagination,Alert } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import moment from "moment-timezone";
import PlaceholderList from '../components/Placeholder';


import API from '../../config/Api';


import { Routes } from "../../routes";
import subjects from '../../data/subjects'



class ComplaintList extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading:true

    }
  }

  componentDidMount(){
     API.post(`/inquiry`,{'type' : 'Complaint'}).then((response) => {
      this.setState({
      list:response.data.data,
      loading:false
      })
    });
    
  }

  render(){

    const TotalComplaint = this.state.list.length;
    const TableRow = (props) => {
    const { id,name,phone,role,user_id,details,file,createdAt } = props;

    return (
      <tr>
        <td>
          <span className="fw-normal">
              <Card.Link as={Link} to={(role == 2) ? `/teacher/${user_id}` : `/user/${user_id}`  } className="fw-normal">
            {name}
             </Card.Link>
          </span>
        </td>
        <td>
          <span className="fw-normal">
            { details}
          </span>
        </td> 
        <td>
          <span className="fw-normal">
             { (file != null) ? <a href={file}>
              <img height='100px' src={file} />
            </a> : 'N/A' } 
           
          </span>
        </td>
        <td>
          <span className="fw-normal">
            {moment(createdAt).format('Do MMM YY')}
          </span>
        </td>
      </tr>
    );
  };

  let alert = this.props.location.state ? this.props.location.state :  '';


    return (<>
      {
        
      }
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            <Breadcrumb.Item active>Complaint List</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Complaint List</h4>
          <p className="mb-0">All Complaint List</p>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
         
        </div>
      </div>

    
     <Card border="light" className="table-wrapper table-responsive shadow-sm">
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
           
              <th className="border-bottom">User </th>
              <th className="border-bottom">Description</th>
              <th className="border-bottom">File</th>
              <th className="border-bottom">Created At</th>
            </tr>
          </thead>
          <tbody>
          { (this.state.loading == true) ? <PlaceholderList colspan='4' /> : this.state.list.map(t => <TableRow  {...t} />)}
          </tbody>
        </Table>
        <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
          <Nav>
          </Nav>
          <small className="fw-bold">
            Showing <b>{TotalComplaint}</b> out of <b>{TotalComplaint}</b> entries
          </small>
        </Card.Footer>
      </Card.Body>
    </Card>
     
    </>);
  }

}


export default ComplaintList



