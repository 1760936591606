import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect, BrowserRouter, HashRouter } from "react-router-dom";
import { Routes } from "../routes";


// pages

import DashboardOverview from "./dashboard/DashboardOverview";
import UserList from './User/UserList'

import ComplaintList from './enquiry/ListComplaint'
import EnquiryList from './enquiry/ListEnquiry'


import UserView from './User/UserView'
import ChatView from './Chat/Chatview'
import TeacherList from './Teacher/Teacher'
import TeacherUnverified from './Teacher/TeacherUnverified'
import TeacherView from './Teacher/TeacherView'
import ScrollToTop from "../components/ScrollToTop";

import SubjectAdd from './Subject/CreateSubject'
import SubjectEdit from './Subject/EditSubject'
import SubjectList from './Subject/ListSubject'


import CountryAdd from './Country/CreateCountry'
import CountryEdit from './Country/EditCountry'
import CountryList from './Country/ListCountry'

import Signin from "./auth/Signin";
import Lock from "./auth/Lock";
import NotFoundPage from "./auth/NotFound";
import ServerError from "./auth/ServerError";





// documentation pages


// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";
import { extend } from 'chartist';



const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem('settingsVisible') === 'false' ? false : true
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (
    <Route {...rest} render={props => (
      <>
        {/* <Preloader show={loaded ? false : true} /> */}
        <Sidebar />

        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </>
    )}
    />
  );
};


class HomePage extends React.Component{
  render(){

let token = localStorage.getItem('token');
if(token == 'false' || token == '' || token == null) {
return (
  <HashRouter>
      <ScrollToTop />
  <Switch>
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
     <Redirect to={Routes.Signin.path} />
  </Switch>
  </HashRouter>);
}

 return(
  <HashRouter>
      <ScrollToTop />
  <Switch>

    {/*  Auth   */}
   
    
    {/*  Dashboard  */}

    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />

    <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />

    <RouteWithSidebar exact path={Routes.ComplaintList.path} component={ComplaintList} />
    <RouteWithSidebar exact path={Routes.EnquiriesList.path} component={EnquiryList} />


    <RouteWithSidebar exact path={Routes.UserView.path} component={UserView} />
    <RouteWithSidebar exact path={Routes.ChatView.path} component={ChatView} />

    <RouteWithSidebar exact path={Routes.TeacherList.path} component={TeacherList} />
    <RouteWithSidebar exact path={Routes.TeacherListUnverified.path} component={TeacherUnverified} />
    <RouteWithSidebar exact path={Routes.TeacherView.path} component={TeacherView} />

    <RouteWithSidebar exact path={Routes.SubjectAdd.path} component={SubjectAdd} />
    <RouteWithSidebar exact path={Routes.SubjectEdit.path} component={SubjectEdit} />
    <RouteWithSidebar exact path={Routes.SubjectView.path} component={SubjectList} />
    

    <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
    <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
    <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />
    
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
    <Redirect to={Routes.DashboardOverview.path} />
  </Switch>
  </HashRouter>
);
  }
}

export default HomePage;


// export default () => {
// const [token, setToken] = useState();
// if(!token) {
// return (
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
//      <Redirect to={Routes.Signin.path} />
//   </Switch>
//   </BrowserRouter>);
   
// }

//  return(
//   <BrowserRouter>
//       <ScrollToTop />
//   <Switch>

//     {/*  Auth   */}
//     <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    
//     {/*  Dashboard  */}

//     <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
//     <RouteWithSidebar exact path={Routes.UserList.path} component={UserList} />
//     <RouteWithSidebar exact path={Routes.TeacherList.path} component={TeacherList} />

//     <RouteWithSidebar exact path={Routes.SubjectAdd.path} component={SubjectAdd} />
//     <RouteWithSidebar exact path={Routes.SubjectEdit.path} component={SubjectEdit} />
//     <RouteWithSidebar exact path={Routes.SubjectView.path} component={SubjectList} />
    

//     <RouteWithSidebar exact path={Routes.CountryAdd.path} component={CountryAdd} />
//     <RouteWithSidebar exact path={Routes.CountryEdit.path} component={CountryEdit} />
//     <RouteWithSidebar exact path={Routes.CountryView.path} component={CountryList} />
    
//     <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />

//     <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
//     <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} /> 
//     <Redirect to={Routes.NotFound.path} />
//   </Switch>
//   </BrowserRouter>
// );
//  }
